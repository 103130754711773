/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 550px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 63.85542168674698%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECA//EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABbMg6IMv/xAAaEAACAwEBAAAAAAAAAAAAAAAAAgEDExES/9oACAEBAAEFAq7mUe+TSCK/Q0KZ8P/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwGq/8QAGxAAAgEFAAAAAAAAAAAAAAAAABFRAQIQMWH/2gAIAQEABj8CkV0M1UbEuY//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMUFhwf/aAAgBAQABPyGz0PsSuLR7LgWR2VMcISgsmV6JUAPOz//aAAwDAQACAAMAAAAQkx//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEVH/2gAIAQMBAT8Q0j//xAAWEQEBAQAAAAAAAAAAAAAAAAAAETH/2gAIAQIBAT8QzEv/xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMVFBkaH/2gAIAQEAAT8QdojqLuvB7wPAAlSHiHzOQEU5ywKSaLJ3m1FIrp1i5abaVz//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"thanksgiving\"\n        title=\"thanksgiving\"\n        src=\"/static/d03b34957da5877ec8a9cceda69da2ec/021b5/thanksgiving.jpg\"\n        srcset=\"/static/d03b34957da5877ec8a9cceda69da2ec/4d5fb/thanksgiving.jpg 166w,\n/static/d03b34957da5877ec8a9cceda69da2ec/558f0/thanksgiving.jpg 333w,\n/static/d03b34957da5877ec8a9cceda69da2ec/021b5/thanksgiving.jpg 550w\"\n        sizes=\"(max-width: 550px) 100vw, 550px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Though there still remains some level of controversy surrounding the actual history and reasons of what we know today as Thanksgiving it is very likely that it can be traced back to England and the Puritans.  In England in the 1500’s there were special holidays called for by the Puritans as a reaction to certain events.  For example, in times of drought or other hardships a period of fasting (Days of Fasting) would be announced and in other times, like a good harvest season, would prompt Days of Thanksgiving.  Of course, as settlers came to the newly colonized North American continent they brought with them such traditions as Thanksgiving.  The most popular idea of the first Thanksgiving was that it was held in Plymouth between the pilgrims and the Wampanoag Indians in the fall of 1621 to celebrate the bountiful harvest.  The fare most likely consisted of not only turkey but geese and ducks as well as venison, fish and stews brought by the Indians.  Of course, pumpkin pie (or a variation of it) may have been present as it is a common symbol of harvest time.  Though Thanksgiving may have begun in the 1620’s it didn’t really become a regular occurrence until the 1660’s and the date for which was not officially proclaimed until the 1940’s."), "\n", React.createElement(_components.p, null, "The First Thanksgiving Celebration"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
